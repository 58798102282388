/*
 * @Author       : TheoHyman 13139330+TheoHyman@user.noreply.gitee.com
 * @Date         : 2023-10-24 09:47:25
 * @LastEditors  : Shihao Wang
 * @LastEditTime : 2023-11-01 21:13:18
 * @FilePath     : \shop-frontend\src\router\index.js
 * @Description  : 默认注释
 *  百度JQueyCDN  : 
 * https://apps.bdimg.com/libs/jquery/2.1.4/jquery.min.js
 */
import Vue from 'vue'
import VueRouter from 'vue-router' 
// 路由导入 或 懒加载
import Login from '../components/Login.vue'

Vue.use(VueRouter)

const routes = [
  {
    path:'/',
    redirect:'/login'
  },
  {
    path: '/login',
    component: Login
  },
  {
    path: '/home',
    component: ()=>import('../components/ShopHome.vue'),
    redirect:'/welcome',
    children:[
      {
        path:'/users',
        component:()=> import('../components/layout/main/users/UserModule.vue')
      },
      {
        path:'/members',
        component:()=> import('../components/layout/main/members/MembersModule.vue')
      },
      {
        path:'/roles',
        component:()=> import('../components/layout/main/roles/RolesModule.vue')
      },
      {
        path:'/rights',
        component:()=> import('../components/layout/main/rights/RightsModule.vue')
      },
      {
        path:'/categories',
        component:()=> import('../components/layout/main/categories/CatModule.vue')
      },
      {
        path:'/goods',
        component:()=> import('../components/layout/main/goods/GoodsModule.vue')
      },
      {
        path:'/goods/add',
        component:()=> import('../components/layout/main/goods/components/AddGoods.vue')
      },
      {
        path:'/params',
        component:()=> import('../components/layout/main/params/ParamsModule.vue')
      },
      
      {
        path:'/orders',
        component:()=> import('../components/layout/main/orders/OrderModule.vue')
      },
      {
        path:'/loginlog',
        component:()=> import('../components/layout/main/loginLog/LoginLog.vue')
      },
      {
        path:'/operlog',
        component:()=> import('../components/layout/main/loginLog/OperLog.vue')
      },
      {
        path:'/welcome',
        component:()=> import('../components/layout/main/Welcome.vue')
      },
      {
        path:'/analysis',
        component:()=> import('../components/layout/main/analysis/Analysis.vue')
      },

    ]
  }
  
]

const router = new VueRouter({
  routes
})

export default router
