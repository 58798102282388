<!--
 * @Author       : TheoHyman 13139330+TheoHyman@user.noreply.gitee.com
 * @Date         : 2023-10-24 09:47:25
 * @LastEditors  : Shihao Wang
 * @LastEditTime : 2023-11-29 14:34:18
 * @FilePath     : \shop-frontend\src\App.vue
 * @Description  : 默认注释 
 *  百度JQueyCDN  : 
 * https://apps.bdimg.com/libs/jquery/2.1.4/jquery.min.js
-->
<template>
  <div
    id="app"
    v-title
    data-title="
自我测试检测学习"
  >
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'app',
  components: {},
}
</script>

<style>
.el-table__expanded-cell {
  padding: 20px 50px !important;
}
</style>
