<template>
  <div class="login-container">
    <div class="login-left">
      <img src="../assets/imgs/login_img.png" />
    </div>
    <div class="login-box">
      <!-- 头像 -->
      <div class="avatar-box">
        <img src="../assets/imgs/login_logo.png" />
      </div>
      <!-- 标题 -->
      <div class="title">电商后台管理系统</div>
      <!-- 登录表单 -->
      <el-form
        class="login-form"
        :model="loginForm"
        :rules="loginFormRules"
        ref="loginFormRef"
      >
        <el-form-item prop="username">
          <el-input
            placeholder="请输入用户名"
            prefix-icon="el-icon-user"
            v-model="loginForm.username"
          ></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            placeholder="请输入密码"
            prefix-icon="el-icon-lock"
            v-model="loginForm.password"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item prop="code">
          <el-input
            placeholder="请输入验证码"
            prefix-icon="el-icon-s-promotion"
            v-model="loginForm.code"
            style="display: inline-block; width: 150px"
          ></el-input>
          <img
            :src="captchaImage"
            @click="getCode"
            style="width: 100px; vertical-align: middle; margin-left: 15px"
          />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" class="login-btn" @click="login"
            >登录</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="icp">
      <img src="https://beian.mps.gov.cn/img/logo01.dd7ff50e.png" />
      <p>
        <a href="https://beian.miit.gov.cn/" target="_blank">
          皖ICP备2023023927号-1</a
        >
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 表单的数据绑定对象
      loginForm: {
        username: '',
        password: '',
        code: '',
        uuid: '',
      },
      captchaImage: '',
      // 表单的验证规则
      loginFormRules: {
        username: [
          { required: true, message: '请输入登录账户', trigger: 'blur' },
          {
            min: 3,
            max: 10,
            message: '长度在 3 到 10 个字符',
            trigger: 'blur',
          },
        ],
        password: [
          { required: true, message: '请输入登录密码', trigger: 'blur' },
          {
            min: 3,
            max: 15,
            message: '长度在 3 到 15 个字符',
            trigger: 'blur',
          },
        ],
      },
    }
  },
  created() {
    // 获取验证码
    this.getCode()
  },
  methods: {
    async getCode() {
      const { data: res } = await this.$http.get('captchaImage')
      if (res.status != 200) {
        return this.$message.error(res.msg)
      }
      this.captchaImage = res.data.img
      this.loginForm.uuid = res.data.uuid
    },
    login() {
      this.$refs.loginFormRef.validate(async (valid) => {
        // 判断是否校验成功
        if (!valid) {
          return
        }
        // 发送请求判断是否登录成功
        const { data: res } = await this.$http.post(
          'captchaLogin',
          this.loginForm
        ) // 直接返回执行的结果
        console.log(res)

        if (res.status != 200) {
          // 登录失败，重新获取验证码
          this.getCode()
          return this.$message.error(res.msg)
        }

        this.$message.success('登录成功')
        // 将用户信息保存到localStorage中
        localStorage.setItem('userInfo', JSON.stringify(res.data))
        this.$router.push('/home') // 跳转到home组件
      })
    },
  },
}
</script>

<style lang="less" scoped>
.icp {
  bottom: 10px;
  position: absolute;
  display: inline;
  text-align: center;
}
.icp p {
  // height: 48px;
  text-align: center;
  display: inline-block;
}
.icp img {
  display: inline;
  height: 40px;
  width: 40px;
}
.login-container {
  background: #93defe;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-left {
  margin-right: 100px;
}

.login-box {
  width: 400px;
  height: 350px;
  background: #fff;
  padding: 50px;
  border-radius: 6px;
  box-sizing: border-box;
  position: relative;

  .avatar-box {
    width: 120px;
    height: 120px;
    border: 5px solid #93defe;
    border-radius: 100px;
    background: #fff;
    text-align: center;
    line-height: 110px;
    position: absolute;
    top: -60px;
    left: 50%;
    transform: translate(-50%);
  }

  .title {
    position: absolute;
    top: 70px;
    left: 50%;
    transform: translate(-50%);
    font-size: 18px;
    color: #444;
  }

  .login-form {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%);
    width: 80%;
    padding: 0 20px;
    box-sizing: border-box;

    .login-btn {
      width: 100%;
    }
  }
}
</style>
