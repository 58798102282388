/*
 * @Author       : TheoHyman 13139330+TheoHyman@user.noreply.gitee.com
 * @Date         : 2023-10-24 09:47:25
 * @LastEditors  : Shihao Wang
 * @LastEditTime : 2023-11-13 12:52:30
 * @FilePath     : \shop-frontend\src\main.js
 * @Description  : 默认注释 
 *  百度JQueyCDN  : 
 * https://apps.bdimg.com/libs/jquery/2.1.4/jquery.min.js
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
//导入 全局样式表 
import './assets/css/global.css'

import moment from "moment"
Vue.prototype.$moment = moment;
Vue.config.productionTip = false


// 导入NProgress包对应的js和css
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

// 添加浏览器标签页标题
Vue.directive('title', {
  inserted: function (el, binding, vnode) {
    document.title = el.dataset.title
  }
});




// axios 配置
import axios from 'axios'
axios.defaults.baseURL = '/api/'
// 拦截所有请求，添加token
axios.interceptors.request.use(config=>{
    // 开启进度条
    NProgress.start()
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  if(userInfo){
    config.headers.Authorization = userInfo.token
  }
  return config
})
// 拦截所有响应，关闭进度条
axios.interceptors.response.use(config => {
  // 关闭进度条
  NProgress.done()
  return config
})


Vue.prototype.$http = axios
// axios 配置



// 导入树形表格
import TreeTable from 'vue-table-with-tree-grid'
Vue.component('tree-table',TreeTable)
// 导入树形表格





// 导入富文本编辑器
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
Vue.use(VueQuillEditor)
// 导入富文本编辑器


new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
